import React from "react"

import Layout from "components/Layout/en"
import ContactUsSection from "components/ContactUsSection/index"
import consultingImg from "./consulting.jpeg"
import { Container, Row, Col } from "react-bootstrap"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

export default function(props) {
  const header = {
    x: 44,
    y: 75,
    text: "Strategy & Innovation",
    description:
      "Consulting in the area of visual artificial intelligence for quality control.",
    image: consultingImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="py-5 text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <FormattedHTMLMessage id="consultingText" />
          </Col>
        </Row>
        <Row className="pb-5 text-center">
          <Col sm="12">
            <FormattedHTMLMessage id="consultingBlueBoxAreasHeader" />
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxVisual" />
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxQuality" />
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxAI" />
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxProduct" />
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxProcess" />
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <FormattedMessage id="consultingBlueBoxModel" />
            </div>
          </Col>
        </Row>
        <Row className="py-5 text-center">
          <Col sm="12">
            <h2>Advantages</h2>
          </Col>
          <Col md="4">
            <h4>25</h4>
            internationally realized projects
          </Col>
          <Col md="4">
            <h4>AI Expertise</h4>
            Agile and use case based strategy consulting
          </Col>
          <Col md="4">
            <h4>1.000.000</h4>
            Image data processing per month
          </Col>
        </Row>
      </Container>

      <ContactUsSection pathConsulting={true} />
    </Layout>
  )
}
